import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {
  RequestService,
  LayoutUtilsService,
  StoreService,
} from "../../shared/services";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: StoreService
  ) {
    store.init("default");
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.store.get("currentUser") && this.store.get("token")) {
      let stateName: string = state.url.replace("/", "");
      let currentUser = this.store.get("currentUser");
      if (
        stateName == "dashboard" &&
        (currentUser.type == "1" || currentUser.isSuperAdmin == "1")
      ) {
        this.router.navigate(["/reseller/dashboard"]);
      }
      return true;
    }
    if (this.requestService.isNBCA) {
      this.router.navigate([environment.registerRoute]);
    } else {
      this.router.navigate([environment.loginRoute]);
    }
    return false;
  }
  private legitUser(user, orgId, appId): boolean {
    return true;
  }
}
