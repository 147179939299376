<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom" *ngIf="data.title">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
					<span *ngIf="data.description2"><br>{{data.description2}}</span>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br>
				<button *ngIf="!data.btnTitle" mat-raised-button (click)="onNoClick()" style="float:right">{{'Ok' | translate}}</button>
				<button *ngIf="data.btnTitle" mat-raised-button (click)="onNoClick()" style="float:right">{{data.btnTitle | translate}}</button>
			</div>
		</div>
	</div>
</div>
