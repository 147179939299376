<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
		<div class="userInfo">
      <mat-card>
				<mat-card-content *ngIf="data.data">
					<mat-list>
					 <mat-list-item><span>{{'Name' | translate}}:</span> {{data.data.name}}</mat-list-item>
					 <mat-list-item><span>{{'Role' | translate}}:</span> {{data.data.role.name}}</mat-list-item>
					 <mat-list-item><span>{{'Email' | translate}}:</span> {{data.data.email}}</mat-list-item>
					</mat-list>
				</mat-card-content>
      </mat-card>
    </div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
	<br/>
</div>
