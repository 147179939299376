import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';

export class My3ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-multiple-select',
  templateUrl: './custom-multiple-select.component.html',
  styleUrls: ['./custom-multiple-select.component.scss']
})
export class CustomMultipleSelectComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  selectData: Array<any> = [];
  /* pagination Info */
  pageSize = 100;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';

  myControl = new FormControl();
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public hideBecauseOne: boolean = false;
  private _parentFormSubmitted = false;
  @Input() clearable: boolean = false;
  @Input() required: boolean = false;
  public esMatcher = new My3ErrorStateMatcher();
  @Input() displayName: string[] = ['name', 'text'];
  @Input() filters: any;
  @Input() hideIfOne: boolean = false;
  @Input() itemName: string = '';
  @Input() placeholder: string = '';
  @Input() dataType: string;
  @Input() canCash: boolean = true;
  @Input() apiTarget: string = 'list';
  public _value: any[];
  @Input()
  set value(value: any[]) {
    this._value = [];
    let valArray = [];
    for (let val of value) {
      valArray.push(val._id);
    }
    this._value = valArray;
  }
  get value(): any[] {
    return this._value;
  }
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean) {
    this._parentFormSubmitted = parentFormSubmitted;
    if (parentFormSubmitted) {
      if(this.form)
        this.form.onSubmit(undefined);
    }
  }
  get parentFormSubmitted(): boolean {
    return this._parentFormSubmitted;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(
    private requestService: RequestService, private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  public onSubmit() {
    // do nothing
  }
  private getSelectedItem(val) {
    for (let itm of this.selectData) {
      if (val === itm._id) {
        return itm
      }
    }
    return '';
  }
  public setAttribute(val) {
    let valT = [];
    if (this.apiTarget === 'search') {
      this.onSelectReturn.emit(val);
    } else {
      if (val)
        valT = val.value;
      let returnVal: any[] = [];
      for (let vl of valT) {
        returnVal.push(this.getSelectedItem(vl));
      }
      this.onSelectReturn.emit(returnVal);
    }
  }
  displayFn(data?: any): string | undefined {
    return data ? data.name : undefined;
  }
  public loadData() {
    if (this.apiTarget === 'search') {
      this.loadDataSearch();
    } else if (this.apiTarget === 'list/organization') {
      this.loadDataListOrg();
    } else if (this.apiTarget === 'list/application') {
      this.loadDataListApp();
    } else {
      this.loadDataList();
    }
  }
  public loadDataList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataL(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
          this.setAutoOne(this.selectData[0]._id);
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      }, this.canCash);
    }
  }
  public loadDataListApp() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataL(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
          this.setAutoOne(this.selectData[0]._id);
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      }, false, this.requestService.appId);
    }
  }
  public loadDataListOrg() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataLByOrg(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        //console.log('this.selectData', this.selectData);
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
          this.setAutoOne(this.selectData[0]._id);
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  public loadDataSearch() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      this.requestService.getDataListSummary(this.dataType, { page: this.pageNumber, term: this.searchText, perpage: this.pageSize, filter: filterConfiguration }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
          this.setAutoOne(this.selectData[0]._id);
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  setAutoOne(id) {
    setTimeout(() => {
      this.onSelectReturn.emit([{ _id: id, appId: this.requestService.appId, name: '' }]);
    }, 300);
  }
  termSearch(e): any {
    this.searchText = e.term;
    this.loadDataSearch();
  }
}
