import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { LayoutComponents, DefaultSettings } from '../';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { roundDecimal } from '../../../../shared/helpers';
import { MatDialog } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../../../../shared/components/iframe-dialog/iframe-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
    selector: 'app-screen-layout-view',
    templateUrl: './screen-layout-view.component.html',
    styleUrls: ['./screen-layout-view.component.scss']
})
export class ScreenLayoutViewComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public selectedUser: any;
  public loadingPicture: boolean = false;
  public defaultSetting = DefaultSettings.defaultSetting;

  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;

  @Input() bgBackgroundColor: any = undefined;
  @Input() bgBackgroundLink: any = undefined;
  @Input() settingObject: any = undefined;

  constructor(private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
  public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.bgUpdate(this.bgBackgroundLink, this.bgBackgroundColor);
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background');
    this.renderer.removeStyle(document.body, 'overflow-y');
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  // dragDropped($event, target) {
  //   let position = $event.source.getFreeDragPosition();
  //   console.log('dragDropped $event', $event);
  //   console.log('dragDroppedposition', position);
  // }
  bgUpdate(bgBackgroundLink, bgBackgroundColor) {
    let styleBG = "background: ";
    if (bgBackgroundColor !== '') {
      styleBG = styleBG + bgBackgroundColor;
    } else {
      styleBG = styleBG + '#efefef';
    }
    if (bgBackgroundLink !== '') {
      styleBG = styleBG + " url(" + bgBackgroundLink + "); background-repeat:no-repeat; background-size:100%; background-attachment: fixed; background-position: center 55px; overflow-y: hidden;";
      this.renderer.setAttribute(document.body, 'style', styleBG);
    } else {
      styleBG = styleBG + ";"
      this.renderer.setAttribute(document.body, 'style', styleBG);
    }
  }
  hasComponent(type){
    for(let cmp of this.settingObject['columns'][0]['components']){
      if(cmp.name === type){
        return true
      }
    }
    return false;
  }
  getHeight(h, w, ratioSize) {
    if(ratioSize){
      return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2) + 'vw';
    }else{
      return h + 'vw';
    }
  }
  getZ(z, index, subIndex) {
    return z;
  }
  getX(x, mainBoundary) {
    return Math.round((x * mainBoundary.offsetWidth)/100);
  }
  getY(y, mainBoundary) {
    return Math.round((y * mainBoundary.offsetWidth)/100);
  }
  getGridBorder(total, idx) {
    return total%idx ==0 ? '0px' : '5px';
  }
  getTileLink(id) {
    return this.requestService.getTileLink(undefined, id, true);
  }
  /**
  * getShapePoint function
  */
  getShapePoint(svg, mainBoundary, index, subIndex) {
      let points = '';
      for (let pnt of svg) {
          points = points + this.getPolyX(pnt.x, mainBoundary, index, subIndex) + ',' + this.getPolyY(pnt.y, mainBoundary, index, subIndex) + ' ';
      }
      return points;
  }
  /**
  * getCircleR function
  */
  getPositiveValue(x1, x2) {
    let r = 0;
    if(x1 > x2){
      r = x1 - x2;
    }else if (x1 > x2){
      r = x2 - x1;
    }
    return r;
  }
  getPolyX(x, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((x * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((x * mainBoundary.offsetWidth)/100);
  }
  getPolyY(y, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((y * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((y * mainBoundary.offsetWidth)/100);
  }
  cancelThru($event) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
  numberReturn(length){
    return new Array(length);
  }
  selectPicture(e, type, link) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (link && link !== '') {
      if (type === 'room') {
        this.viewData(link)
      } else if (type === 'tile') {
        this.openIframe(link)
      } else if (type === 'page') {
        this.router.navigate(['/' + link], { relativeTo: this.activatedRoute });
      } else {
        window.open(link, '_blank');
      }
    }
  }

  public viewData(roomId) {
    if (!this.loadingPicture) {
      this.loadingPicture = true;
      this.requestService.getSingleData('room', roomId, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loadingPicture = false;
        if (data) {
          let roomData = data.results;
          let currentRoomUserRole = this.requestService.getUserRoomRole(roomData);
          if (currentRoomUserRole === 'owner' || currentRoomUserRole === 'admin' || (roomData.opentoall && currentRoomUserRole === 'edit')) {
            this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          } else {
            if (roomData.opentoall || roomData.main) {
              this.getSessions(roomData);
            } else {
              this.layoutUtilsService.showNotification(this.translate.instant('You have no access to this ') + this.translate.instant('room'), this.translate.instant('Dismiss'));
              // this.router.navigate(['/rooms/list'], { relativeTo: this.activatedRoute });
            }
          }
        }
      });
    }
  }
  openIframe(tileId) {
    const dialogRef = this.dialog.open(ModalIframeDialogComponent, {
      width: '1600px',
      data: {
        //dataType: this.dataType,
        //title: this.translate.instant('Details'),
        data: this.requestService.getTileLink(undefined, tileId, true),
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
      }
    });
  }

  getSessions(roomData) {
    let roomId = roomData._id;
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { '$or': [{ "opentoall": true }, { "users._id": { "$in": [this.selectedUser._id] } }, { "createdBy": { "$eq": this.selectedUser._id } }] }]
    // };
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { "opentoall": true }]
    // };
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt', 'url', 'recordSource'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        let sessionsList = data.results;
        if (sessionsList && sessionsList.length > 0) {
          let currentSession = undefined;
          let currentDate: Moment = moment.utc();
          if (sessionsList.length > 1) {
            let activeSession = undefined;
            for (let lec of sessionsList) {
              if (lec.active) {
                activeSession = lec;
                break;
              }
            }
            currentSession = activeSession;
            if (!currentSession) {
              for (let lec of sessionsList) {
                let startD: Moment = moment.utc(lec.startsAt).local();
                let endD: Moment = moment.utc(lec.endAt).local();
                if ((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) {
                  activeSession = lec;
                  break;
                }
              }
            }
            currentSession = activeSession;
          } else {
            currentSession = sessionsList[0];
          }
          if (currentSession) {
            let currentSessionUserRole = this.requestService.getUserSessionRole(currentSession);
            let leads = this.requestService.getSessionRoleByType(currentSession, 'lead');
            if (!roomData.main && leads.length == 0) {
              this.layoutUtilsService.showNotification('A ' + this.translate.instant('lead') + ' needs to be assigned', this.translate.instant('Dismiss'));
            } else {
              let masterModeratorId = this.requestService.getSessionHost(currentSession);
              if (!roomData.main && !masterModeratorId) {
                this.layoutUtilsService.showNotification(this.translate.instant('A host needs to be assigned'), this.translate.instant('Dismiss'));
              } else {
                if (currentSession.opentoall || currentSessionUserRole !== 'anonymous') {
                  if (!currentSession.active && currentSession.recordSource && currentSession.recordSource.trim() !== '') {
                    window.location.href = '/#/rooms/' + roomId + '/session/' + currentSession._id + '/record';
                  } else if (currentSession.url && currentSession.url.trim() !== '') {
                    window.open(currentSession.url.trim(), '_blank');
                  } else {
                    window.location.href = '/#/rooms/' + roomId + '/sessions/' + currentSession._id;
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);
                  }
                  return;
                } else {
                  this.layoutUtilsService.showNotification('Oops! This ' + this.translate.instant('session') + ' is limited to specific attendees.', 'Dismiss');
                }
              }
            }
          } else {
            this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          }
        } else {
          // this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
        }
      }
    });
  }
}
