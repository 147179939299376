import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-empty',
    templateUrl: './empty.component.html',
})
export class EmptyComponent {
    constructor() {
    }
}
