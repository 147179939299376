import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { LayoutService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { LayoutComponents, DefaultSettings } from '../';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LayoutDynamicDataComponent } from '../../../../shared/components/layout-components/layout-dynamic-data/layout-dynamic-data.component';

@Component({
    selector: 'app-layout-component',
    templateUrl: './layout-component.component.html',
    styleUrls: ['./layout-component.component.scss']
})
export class LayoutComponent implements OnInit {
  public subscriptions: any[] = <any>[];

  public data: any = undefined;
  public backLayer: boolean = false;

  @ViewChild('layoutdynamicdata') layoutdynamicdata: LayoutDynamicDataComponent;
  constructor(private layoutUtilsService: LayoutUtilsService, private layoutService: LayoutService, private translate: TranslateService, public renderer: Renderer2, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.layoutService.data.subscribe((data) => {
        if (data) {
          this.data = data;
        }
      })
    );
    this.subscriptions.push(
      this.layoutService.appLayoutSubject.subscribe((data) => {
        if (data) {
          this.handleActions(data)
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  handleActions(action: any): void {
    if(action.name === 'create'){
      this.layoutdynamicdata.saveData('create');
    }else if (action.name === 'edit'){
      this.layoutdynamicdata.saveData('edit');
    }else if (action.name === 'reset'){
      this.layoutdynamicdata.resetSettings();
    }else if (action.name === 'default'){
      this.layoutdynamicdata.loadDefaultSettings();
    }else if (action.name === 'select'){
      this.layoutdynamicdata.loadTemplateSettings();
    }else if (action.name === 'savetemplate'){
      this.layoutdynamicdata.saveTemplateSettings();
    }else if (action.name === 'setViewMode'){
      this.layoutdynamicdata.setViewModeReturn(undefined, action.value);
    }else if (action.name === 'setBackLayer'){
      this.backLayer = action.value;
    }else if (action.name === 'updateroom'){
      this.layoutdynamicdata.data.roomData = action.value;
    }
  }
  closeModal(result) {
    if (result) {
      //console.log('result', result);
    } else {
      this.router.navigate(['/admin/layouts'], { relativeTo: this.activatedRoute });
    }
  }
  setStreamMode(result) {
    this.layoutService.streamMode.next(result);
  }
  setViewMode(result) {
    this.layoutService.viewMode.next(result);
  }
  setSessionData(result) {
    this.layoutService.sessionData.next(result);
  }
}
