import { Observable, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService, StoreService, LayoutUtilsService } from '../../services';
import { userType } from '../../enums';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    public title = environment.serverName;
    public selectedUser: any;
    private currentUser;
    public isReseller = false;
    public isSuperAdmin = false;
    public isILIUser = false;
    public currentImgSrc: any = "../../../../../../../assets/images/user-placeholder.png"
    isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
        Breakpoints.Web
    );
    public isMobile: boolean = false;

    @Input('user-view') public isUser;

    constructor(public requestService: RequestService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private storeService: StoreService,
        private layoutUtilService: LayoutUtilsService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = deviceService.isMobile();
    }

    ngOnInit(): void {
        this.storeService.init('default');

        this.currentUser = this.storeService.get('currentUser');
        if (this.currentUser.type == "1") {
            this.isReseller = true;
        } else if (this.currentUser.type == '3') {
            this.isILIUser = true;
        }
        this.isSuperAdmin = this.currentUser.isSuperAdmin || false;
        if (this.currentUser.photo) {
            this.currentImgSrc = this.currentUser.photo;
        }

        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.selectedUser = data;
                    // console.log('currentUserSubject', this.selectedUser)
                    if (data.photo && data.photo !== '') {
                        this.currentImgSrc = data.photo;
                    }

                }
            })
        );
    }

    public toProfile() {
        this.router.navigate(['/dashboard/account'])
    }

    public toBilling() {
        this.router.navigate(['/dashboard/billing'])
    }

    public toSupport() {
        this.router.navigate(['/dashboard/support'])
    }

    public toInvoices() {
        this.router.navigate(['/dashboard/invoices'])
    }

    public toStripeInvoices() {
        this.router.navigate(['/dashboard/stripeinvoices'])
    }
    public toAdmin() {
        this.router.navigate(['/admin'])
    }

    // public toHome() {

    //     if (this.isReseller)
    //         this.router.navigate(['/reseller/dashboard'])
    //     else
    //         this.router.navigate(['/dashboard'])
    // }

    public logout() {
        this.storeService.clear('default');
        this.router.navigate([environment.loginRoute])
    }
    onCopy() {
        let regUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/#/register/rs?uid=' + this.selectedUser.uid
        regUrl = regUrl.replace(/CUS-/ig, "")
        navigator.clipboard.writeText(regUrl).then(res => {
            this.layoutUtilService.showNotification("Link copied", "Dismiss")
        })
    }

}
