<mat-card [ngClass]="bgClass">
    <mat-card-header>
        <div mat-card-avatar>
            <mat-icon class="icon-lg">{{icon}}</mat-icon>
        </div>
        <mat-card-title>{{count}}</mat-card-title>
        <mat-card-subtitle>{{label}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <a href="javascript:void(0)" class="float-right card-inverse">
            View Details
        </a>
    </mat-card-actions>
</mat-card>