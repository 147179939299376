// Angular
import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'kt-confirm-entity-dialog',
	templateUrl: './confirm-entity-dialog.component.html',
	styleUrls: ['../modal.scss']
})
export class ConfirmEntityDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<ConfirmEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private zone: NgZone
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.zone.run(() => {
			this.dialogRef.close();
		});
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		this.zone.run(() => {
			this.dialogRef.close(true);
		});
	}
}
