// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'kt-confirm-rtmp-entity-dialog',
	templateUrl: './confirm-rtmp-entity-dialog.component.html',
	styleUrls: ['../modal.scss']
})
export class ConfirmRTMPEntityDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	// // public myreg = /(^|\s)((rtmp?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
	// public myreg = /(rtmp?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?/gi;
	url = new FormControl('', [Validators.required]);
	name = new FormControl('', [Validators.required]);

	constructor(
		public dialogRef: MatDialogRef<ConfirmRTMPEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		// console.log('data', data)
		this.url.setValue(data.url);
		this.name.setValue(data.name);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		this.dialogRef.close({ url: this.url.value, name: this.name.value });
	}

	markTouched() {
		this.url.markAsTouched();
		this.url.updateValueAndValidity();
		this.name.markAsTouched();
		this.name.updateValueAndValidity();
	}

	// getErrorMessage() {
	// 	return this.url.hasError('required') ? 'You must enter a value' :
	// 		this.url.hasError('email') ? 'Not a valid url' :
	// 			'';
	// }
}
