<div class='ili-panel feed_panel'>
  <div class="input-group input-group-sm feed_content">
    <mat-form-field class="inputInside">
      <mat-checkbox value="true" [checked]="block.data.facebook" [(ngModel)]="block.data.facebook" (change)="onChange($event)">
        <input matInput type="text" placeholder="Facebook feed url" [(ngModel)]="block.data.facebookurl" class="form-control" (change)="onChange($event)">
      </mat-checkbox>
    </mat-form-field>
    <mat-form-field class="inputInside">
      <mat-checkbox value="true" [checked]="block.data.twitter" [(ngModel)]="block.data.twitter" (change)="onChange($event)">
        <input matInput type="text" placeholder="Twitter feed url" [(ngModel)]="block.data.twitterurl" class="form-control" (change)="onChange($event)">
      </mat-checkbox>
    </mat-form-field>
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
        <div fxFlex>
          <mat-form-field class="inputInside">
            <mat-checkbox value="true" [checked]="block.data.instagram" [(ngModel)]="block.data.instagram" (change)="onChange($event)">
              <input matInput type="text" placeholder="Instagram User Id" [(ngModel)]="block.data.instaUserId" class="form-control" (change)="onChange($event)">
            </mat-checkbox>
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="smallFix">
              <input matInput type="text" placeholder="Instagram Access Token" [(ngModel)]="block.data.instaAccessToken" class="form-control" (change)="onChange($event)">
          </mat-form-field>
        </div>
    </div>

  </div>
</div>
