import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from "@angular/router";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
})

export class NotificationComponent implements OnInit, OnDestroy {

    constructor(private _bottomSheetRef: MatBottomSheetRef<NotificationComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private router: Router) { }

    ngOnInit() { }

    ngOnDestroy() { }

    openLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
        event.preventDefault();
    }

    goToLink(link: string) {
        if (link) {
            this._bottomSheetRef.dismiss();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate([link]));
        }
    }
}