import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService, MenuConfigService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
// Object path
import * as objectPath from 'object-path';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    public selectedUser: any;
    public hasListingDirectory: boolean = environment.hasListingDirectory;
    public showMenu: string;
    public menuConfigs: any[] = [];
    constructor(private changeDetectionRef: ChangeDetectorRef, private requestService: RequestService, private menuConfigService: MenuConfigService) {
        this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
    }

    ngOnInit() {
      this.subscriptions.push(
        this.menuConfigService.onConfigUpdated$.subscribe((data) => {
          if (data) {
            this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
          }
        })
      );
      this.subscriptions.push(
        this.requestService.currentUserSubject.subscribe((data) => {
          if (data) {
            this.selectedUser = data;
          }
        })
      );
      this.showMenu = 'admin';
    }
    /**
     * On Destroy
     */
    ngOnDestroy() {
      // if (!this.changeDetectionRef['destroyed']) {
      //     this.changeDetectionRef.detectChanges();
      // }
      this.subscriptions.forEach(el => el.unsubscribe());
    }
    clickingOutside(){
      const dom: any = document.querySelector('body');
      dom.classList.remove('push-right');
    }
    hideMenu(){
      this.toggleSidebar();
    }
    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('push-right');
    }
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }
}
