import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class DomainRedirectResolver implements Resolve<any> {
  constructor(private router: Router) {}

  resolve() {
    const domain = window.location.hostname;

    if (domain.includes("nbca")) {
      return this.router.navigate(["/register"]);
    } else {
      return this.router.navigate(["/login"]);
    }

    return this.router.navigate(["/login"]);
  }
}
