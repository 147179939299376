<div class='ili-panel cart_panel'>
  <p style="font-size: 11px; float: left; margin-left: 12px;">This widget allows confirm all your added carts</p>
  <div class="row cart_text_row">
    <input type="text" [(ngModel)]="block.data.productTitle" class="form-control input-sm cart_product_title" placeholder="Cart Title">
  </div>
  <div class="row cart_text_row">
    <input type="text" [(ngModel)]="block.data.notificationEmail" class="form-control input-sm cart_email_notification" placeholder="Notification email address">
  </div>
  <div class="row cart_text_row">
    <input type="text" [(ngModel)]="block.data.textConfirmButton" class="form-control input-sm cart_confirm_order" placeholder="Confirm Order">
  </div>
  <div class="row cart_text_row">
    <input type="text" [(ngModel)]="block.data.confirmationMessage" class="form-control input-sm cart_confirm_msg" placeholder="Confirmation Message">
  </div>
</div>
