import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-margin-view',
    templateUrl: './margin-view.component.html',
    styleUrls: ['./margin-view.component.scss']
})
export class MarginViewComponent implements OnInit {
    public hValue: number = 0;
    public vValue: number = 0;
    public defaultValue: string = '0VH 0VW 0VH 0VW';
    public _marginValue: string = '0VH 0VW 0VH 0VW';
    @Input()
    set marginValue(marginValue: string) {
      this._marginValue = marginValue;
      this.setMargin();
    }
    get marginValue(): string {
      return this._marginValue;
    }

    @Output() returnMarginValue = new EventEmitter<string>();
    constructor() {
    }
    ngOnInit() {
    }
    setMargin(){
      let currentArray = this.marginValue.split(' ');
      if(currentArray.length !== 4){
        this.marginValue = JSON.parse(JSON.stringify(this.defaultValue));
      }else{
        if(parseFloat(currentArray[0]) === 0 && parseFloat(currentArray[2]) === 0){
          this.vValue = 0;
        }
        if(parseFloat(currentArray[1]) === 0 && parseFloat(currentArray[3]) === 0){
          this.hValue = 0;
        }
        if(parseFloat(currentArray[0]) > 0){
          this.vValue = parseFloat(currentArray[0]);
        }else if (parseFloat(currentArray[2]) > 0){
          this.vValue = -1 * parseFloat(currentArray[2]);
        }else{
          this.vValue = 0;
        }
        if(parseFloat(currentArray[1]) > 0){
          this.hValue = parseFloat(currentArray[1]);
        }else if (parseFloat(currentArray[3]) > 0){
          this.hValue = -1 * parseFloat(currentArray[3]);
        }else{
          this.hValue = 0;
        }
      }
    }
    setDataH(event){
      let currentArray = this.marginValue.split(' ');
      this.hValue = event;
      if(event > 0){
        this._marginValue = parseFloat(currentArray[0]) + 'VH ' + event + 'VW ' + parseFloat(currentArray[2]) + 'VH 0VW';
      }else if (event < 0){
        this._marginValue = parseFloat(currentArray[0]) + 'VH 0VW ' + parseFloat(currentArray[2]) + 'VH ' + Math.abs(event) + 'VW';
      }else{
        this._marginValue = parseFloat(currentArray[0]) + 'VH 0VW ' + parseFloat(currentArray[2]) + 'VH 0VW';
      }
      this.returnMarginValue.emit(this.marginValue);
    }
    setDataV(event){
      let currentArray = this.marginValue.split(' ');
      this.vValue = event;
      if(event > 0){
        this._marginValue = event + 'VH ' + parseFloat(currentArray[1]) + 'VW 0VH ' + parseFloat(currentArray[3])+ 'VW';
      }else if (event < 0){
        this._marginValue = '0VH ' + parseFloat(currentArray[1]) + 'VW ' + Math.abs(event) + 'VH ' + parseFloat(currentArray[3])+ 'VW';
      }else{
        this._marginValue = '0VH ' + parseFloat(currentArray[1]) + 'VW 0VH ' + parseFloat(currentArray[3])+ 'VW';
      }
      this.returnMarginValue.emit(this.marginValue);
    }
}
