<mat-card class="example-card">
	<mat-card-content>
		<!-- <div *ngIf="dataList === 0">
				No Data
		</div>
		<div *ngIf="dataList.length > 0">
			<mat-list role="list" *ngFor="let itm of dataList">
				<mat-list-item role="listitem">{{itm.datetime | myFromNowPipe}}: {{itm.user}} answered ({{itm.answer}}) for question ({{itm.question}})</mat-list-item>
			</mat-list>
		</div> -->
		<div *ngIf="dataListViewKeys">
			<div class="mainQuestions" *ngFor="let itm of dataListViewKeys">
				<div class="itemQuestion">
					{{itm}}
				</div>
				<div class="dataQuestion">
					<table style="width:100%">
					  <tr>
					    <th>{{'User' | translate}}</th>
					    <th>{{'Time'  | translate}}</th>
					    <th>{{'Answer'  | translate}}</th>
					  </tr>
					  <tr *ngFor="let itm of dataListView[itm]">
					    <td>{{itm.user}}</td>
					    <td>{{itm.datetime | myFromNowPipe}}</td>
					    <td>{{itm.answer}}</td>
					  </tr>
					</table>
					<hr />
					<!-- <mat-list role="list" *ngFor="let itm of dataListView[itm]">
						<mat-list-item role="listitem">{{itm.user}} {{itm.datetime | myFromNowPipe}} {{itm.answer}}</mat-list-item>
					</mat-list> -->
				</div>
			</div>
			<!-- <mat-list role="list" *ngFor="let itm of dataList">
				<mat-list-item role="listitem">{{itm.datetime | myFromNowPipe}}: {{itm.user}} answered ({{itm.answer}}) for question ({{itm.question}})</mat-list-item>
			</mat-list> -->
		</div>
	</mat-card-content>
</mat-card>
