import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';
import { LoaderService, RequestService } from '../../../../shared/services';
import { Utils } from '../../../../shared/helpers/utils';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmCkeditorEntityDialogComponent, ConfirmInputEntityDialogComponent } from '../../modals';

@Component({
  selector: 'questionnaire-block',
  templateUrl: './questionnaire-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './questionnaire-block.component.scss']
})
export class QuestionnaireBlockComponent implements BlockComponent {
  @Input() block: any;
  @Output() questionnaireView = new EventEmitter<any>();
  public tileCategories: any[] = [];
  public config = {
    uiColor: '#F0F3F4',
    height: '250',
    allowedContent: true,
    removeButtons: 'Image'
  };
  constructor(private requestService: RequestService, public utils: Utils,
    public dialog: MatDialog, private translate: TranslateService) {
    // console.log('block', this.block);
  }
  ngOnInit() {
    this.getTileCategory();
  }
  public onChange(e: any) {
    // console.log('onChange', this.block);
    this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
  }

  getTileCategory() {
    this.requestService.getDataLByOrgType('category', 'tileblock', (data, error) => {
      if (error) {
        //console.log(error);
      }
      if (data) {
        this.tileCategories = data.results;
      }
    }, false);
  };
  // public onChangePopup(idx, data) {
  //     this.block.data.popup[idx] = data;
  //
  //     this.questionnaireView.emit({'action': 'questionnaireView', block: this.block});
  // }
  // public onChangeConfirmation(idx, data) {
  //     this.block.data.confirmation[idx] = data;
  //
  //     this.questionnaireView.emit({'action': 'questionnaireView', block: this.block});
  // }

  addOption(e: any) {
    e.preventDefault();

    var optData = {
      "option": "",
      "alert": "",
      "confirmation": "",
      "popup": ""
    };

    this.block.data.options.push(optData);

    // var popLength = this.block.data.popup.length;
    // var alertsLength = this.block.data.alerts.length;
    // var confirmLength = this.block.data.confirmation.length;

    // if (popLength > 0) {
    //   this.addPopup("",0);
    // }
    //
    // if (alertsLength > 0) {
    //   this.addAlert("",0);
    // }
    //
    // if (confirmLength > 0) {
    //   this.addConfirm("",0);
    // }
  };

  addSubOption(e: any, idx: any, type: string) {
    e.preventDefault();

    if (!this.block.data.options[idx].hasOwnProperty("subQuestions")) {
      this.block.data.options[idx]["subQuestions"] = [];
    }

    if (type === "questions") {
      this.block.data.options[idx]["subQuestions"].push({
        "type": "questions",
        "questionText": "",
        "questionType": "single",
        "inputControlType": "radio",
        "options": [{ "option": "" }, { "option": "" }]
      });
    } else if (type === "description") {
      this.block.data.options[idx]["subQuestions"].push({
        "type": "description",
        "controlType": "text",
        "questionText": ""
      });
    }
  };

  controlQuestionType(type: any) {
    if (type === "multiple") {
      this.block.data.showInApp = false;
      this.block.data.isNote = false;
    }
    this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
  };

  controlChange(value: any) {
    this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
  };

  trackByIndex(index: number, obj: any): any {
    return index;
  };

  deleteLevel(level: any) {
    // console.log('deleteLevel', level, this.block);
    this.block.data.options[level.parentIndex].subQuestions.splice(level.currentIndex, 1);
  };
  updateLevel(level: any) {
    // console.log('updateLevel', level, this.block.data.options[level.parentIndex].subQuestions[level.currentIndex]);
  };

  // addPopup(e: any, index: number, deleteIt: boolean = false) {
  //   var quesLength = this.block.data.options.length;
  //   var popLength = this.block.data.popup.length;
  //
  //   if(!deleteIt){
  //     if (quesLength > popLength) {
  //       var popToAppend = quesLength - popLength;
  //       var contentValue = new String("");
  //
  //       for (let i = 1; i <= popToAppend; i++) {
  //         this.block.data.popup.push(contentValue);
  //       }
  //     }
  //   }else{
  //     this.block.data.popup = [];
  //   }
  // };
  editPopup(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Pop Up'),
        data: this.block.data.options[index].popup,
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.options[index].popup = result;
        this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
      }
    });
  };

  // addAlert(e: any, index: number, deleteIt: boolean = false) {
  //   var quesLength = this.block.data.options.length;
  //   var alertsLength = this.block.data.alerts.length;
  //   if(!deleteIt){
  //     if (quesLength > alertsLength) {
  //       var alertsToAppend = quesLength - alertsLength;
  //
  //       for (let i = 1; i <= alertsToAppend; i++) {
  //         this.block.data.alerts.push("");
  //       }
  //     }
  //   }else{
  //     this.block.data.alerts = [];
  //   }
  // };
  editAlert(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Alert'),
        data: this.block.data.options[index].alert,
        placeholder: this.translate.instant('Type email address to receive an alert when corresponding option is selected'),
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.options[index].alert = result;
        this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
      }
    });
  };
  // addConfirm(e: any, index: number, deleteIt: boolean = false) {
  //   var quesLength = this.block.data.options.length;
  //   var confirmLength = this.block.data.confirmation.length;
  //   if(!deleteIt){
  //     if (quesLength > confirmLength) {
  //       var confirmToAppend = quesLength - confirmLength;
  //
  //       for (let i = 1; i <= confirmToAppend; i++) {
  //         this.block.data.confirmation.push("");
  //       }
  //     }
  //   }else{
  //     this.block.data.confirmation = [];
  //   }
  // };
  editConfirm(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Confirmation'),
        data: this.block.data.options[index].confirmation,
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.options[index].confirmation = result;
        this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
      }
    });
  };

  removeOption(e: any, index) {
    if (index !== -1 && this.block.data.options.length > 1) {
      this.block.data.options.splice(index, 1);
      // this.block.data.popup.splice(index, 1);
      // this.block.data.alerts.splice(index, 1);
      // this.block.data.confirmation.splice(index, 1);
    }
    this.questionnaireView.emit({ 'action': 'questionnaireView', block: this.block });
  };
}
