export const environment = {
  production: true,
  lockSite: false,
  serverUrl: 'https://api.stellar.online/v3/',
  authServerUrl: 'https://api.stellar.online/api/',
  serverV1Url: 'https://api.stellar.online/api/',
  productId: 'c0cf4ddc-dc79-11ed-afa1-0242ac120002',
  stellarProductId: '1ec4a3a3-6223-486c-a264-0a778895b295',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 300, // if you keep app inactive pop a message
  repeatValidateSeconds: 300,
  hasListingDirectory: false,
  identityName: 'Reg Demo',
  serverName: 'Stellar Registration',
  orgType: 'school',
  orgSubType: 'school',
  subServerName: 'Stellar Registration',
  profileImageURL: '',
  enableTranslation: false,
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',// Viewer
    roleEdit: '5ee761c8d3123a0ef0d8cb61',// Editor
  },
  customKeysView: {
    Admin: 'Admin',
    Student: 'View',
    Teacher: 'Edit',
  },
  tokBox: {
    apiKey: '46643372'
  },
  ipStack: {
    apiKey: '4e73b76fa09e6cfe386cf11e94c11e90'
  },
  ST_PUB_KEY: "pk_live_51IBPEQKKCurCNdVKD5O6EhkMFLojfA2eSWu5NCJsP7yZKdbubkbeh6pZUB101YDAigG6cxe4mKTANs3HVEybrKcy00fI2lOAi8",
  ST_SECRET_KEY: "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",
  FB_APP_ID: "1479396288933958",
  STELLAR_URL: 'https://conference.stellar.online/',
  mainUrl: 'https://accounts.stellar.online',
  PACKAGE: {
    YEARLY: 'PKG-UEtHLTI=',
    MONTHLY: 'PKG-UEtHLTE='
  },
  STRIPE_PAYMENT_LINKS: {
    //no connected account => direct client
    "default_cloudlive_A": "https://buy.stripe.com/28o03k8LG4rFafm14Q",
    "default_cloudlive_B": "https://buy.stripe.com/8wMdUa5zue2f73aaFr",
    "default_cloudlive_C": "https://buy.stripe.com/eVacQ62ni1ftcnu28W",
    "default_booths": "https://buy.stripe.com/3cs2bs0fabU7afm6pd",
    "default_full_0": "https://buy.stripe.com/4gw8zQ9PKcYb3QY7ti",
    "default_full_1": "https://buy.stripe.com/6oEg2ibXS9LZ1IQcND",
    "default_full_2": "https://buy.stripe.com/dR617oaTO9LZbjqaFw",
    "default_full_3": "https://buy.stripe.com/cN2bM2e609LZ5Z600T",
    "default_lite_0": "https://buy.stripe.com/28oaHYfa48HV4V2292",
    "default_lite_1": "https://buy.stripe.com/9AQdUa9PKaQ387e6pj",
    "default_lite_2": "https://buy.stripe.com/fZe9DUd1W6zN0EM00W",
    "default_lite_3": "https://buy.stripe.com/cN28zQ9PKcYbbjq3d9",

    // friedbert@interactivelife.com : acct_1JP8MCQOyXzjgHbH
    "acct_1JP8MCQOyXzjgHbH_full_0": "https://buy.stripe.com/6oEaHY5zubU71IQeUU",
    "acct_1JP8MCQOyXzjgHbH_full_1": "https://buy.stripe.com/8wMcQ6bXS2jx2MU5kl",
    "acct_1JP8MCQOyXzjgHbH_full_2": "https://buy.stripe.com/aEUcQ65zubU70EMdQS",
    "acct_1JP8MCQOyXzjgHbH_full_3": "https://buy.stripe.com/14k4jAe60gan3QY28b",
    "acct_1JP8MCQOyXzjgHbH_lite_0": "https://buy.stripe.com/4gwdUafa49LZafmbIM",
    "acct_1JP8MCQOyXzjgHbH_lite_1": "https://buy.stripe.com/3cs17oe60bU75Z6aEJ",
    "acct_1JP8MCQOyXzjgHbH_lite_2": "https://buy.stripe.com/14kg2i4vqcYbafm006",
    "acct_1JP8MCQOyXzjgHbH_lite_3": "https://buy.stripe.com/8wM2bs3rm9LZbjq3cj",
    "acct_1JP8MCQOyXzjgHbH_cloudlive_A": "https://buy.stripe.com/9AQeYe9PK7DRevC00w",
    "acct_1JP8MCQOyXzjgHbH_cloudlive_B": "https://buy.stripe.com/aEU3fw9PK0bpgDKdRn",
    "acct_1JP8MCQOyXzjgHbH_cloudlive_C": "https://buy.stripe.com/cN2g2i9PK0bp0EM14C",
    "acct_1JP8MCQOyXzjgHbH_booths": "https://buy.stripe.com/dR6eYe4vq8HV87e28H",

    // ABOVE THE CLOUD MEDIA & MARKETING, LLC : acct_1J08jBQK9XY2tHyZ
    "acct_1J08jBQK9XY2tHyZ_full_0": "https://buy.stripe.com/bIY6rI9PK6zN87efZ6",
    "acct_1J08jBQK9XY2tHyZ_full_1": "https://buy.stripe.com/7sI03kge82jx0EM7sB",
    "acct_1J08jBQK9XY2tHyZ_full_2": "https://buy.stripe.com/8wMdUa2ni2jx3QYeV4",
    "acct_1J08jBQK9XY2tHyZ_full_3": "https://buy.stripe.com/eVa8zQ7HC8HV0EM28j",
    "acct_1J08jBQK9XY2tHyZ_lite_0": "https://buy.stripe.com/4gwcQ66Dy9LZ73a5kw",
    "acct_1J08jBQK9XY2tHyZ_lite_1": "https://buy.stripe.com/eVaaHY1jeaQ3gDK3cp",
    "acct_1J08jBQK9XY2tHyZ_lite_2": "https://buy.stripe.com/14k03kbXS7DR87ebIW",
    "acct_1J08jBQK9XY2tHyZ_lite_3": "https://buy.stripe.com/14k9DU7HC7DR2MU3cr",
    "acct_1J08jBQK9XY2tHyZ_cloudlive_A": "https://buy.stripe.com/8wMaHYd1WcYb87eeVu",
    "acct_1J08jBQK9XY2tHyZ_cloudlive_B": "https://buy.stripe.com/cN23fw3rm5vJ3QY14F",
    "acct_1J08jBQK9XY2tHyZ_cloudlive_C": "https://buy.stripe.com/5kA4jA0facYb73abJk",
    "acct_1J08jBQK9XY2tHyZ_booths": "https://buy.stripe.com/5kAg2iaTOf6jevC00D",

    // jasonarcega@gmail.com : acct_1Iye7RQNTfMacDOo
    "acct_1Iye7RQNTfMacDOo_full_0": "https://buy.stripe.com/9AQ3fw8LGaQ3cnucN2",
    "acct_1Iye7RQNTfMacDOo_full_1": "https://buy.stripe.com/cN26rI5zucYb3QYbIZ",
    "acct_1Iye7RQNTfMacDOo_full_2": "https://buy.stripe.com/7sIdUa9PKbU71IQeVc",
    "acct_1Iye7RQNTfMacDOo_full_3": "https://buy.stripe.com/aEUdUaaTO4rFcnubJ1",
    "acct_1Iye7RQNTfMacDOo_lite_0": "https://buy.stripe.com/fZeaHYge87DR9bi9AU",
    "acct_1Iye7RQNTfMacDOo_lite_1": "https://buy.stripe.com/00g7vMe60cYbgDK4gB",
    "acct_1Iye7RQNTfMacDOo_lite_2": "https://buy.stripe.com/eVa17od1WbU70EM8wS",
    "acct_1Iye7RQNTfMacDOo_lite_3": "https://buy.stripe.com/aEU4jAd1We2fdry7sP",
    "acct_1Iye7RQNTfMacDOo_cloudlive_A": "https://buy.stripe.com/eVa7vM0faaQ30EM6p2",
    "acct_1Iye7RQNTfMacDOo_cloudlive_B": "https://buy.stripe.com/3csbM29PK8HV87e5kZ",
    "acct_1Iye7RQNTfMacDOo_cloudlive_C": "https://buy.stripe.com/bIYbM22ni9LZfzG6p4",
    "acct_1Iye7RQNTfMacDOo_booths": "https://buy.stripe.com/14k5nEaTO4rF9bi14L",

    // dave@focus-av-led.com : acct_1IyGiKQS9hvlT4Bm
    "acct_1IyGiKQS9hvlT4Bm_full_0": "https://buy.stripe.com/bIY5nE7HCbU7gDKeVi",
    "acct_1IyGiKQS9hvlT4Bm_full_1": "https://buy.stripe.com/6oE17oe609LZfzGfZn",
    "acct_1IyGiKQS9hvlT4Bm_full_2": "https://buy.stripe.com/dR65nEbXSgan0EM9B0",
    "acct_1IyGiKQS9hvlT4Bm_full_3": "https://buy.stripe.com/3cs03kbXS1ftevC9B1",
    "acct_1IyGiKQS9hvlT4Bm_lite_0": "https://buy.stripe.com/7sI17od1W4rF3QY6oQ",
    "acct_1IyGiKQS9hvlT4Bm_lite_1": "https://buy.stripe.com/14k2bsbXScYbafmcNf",
    "acct_1IyGiKQS9hvlT4Bm_lite_2": "https://buy.stripe.com/fZe4jA5zu0bp2MUcNg",
    "acct_1IyGiKQS9hvlT4Bm_lite_3": "https://buy.stripe.com/bIY6rI3rm3nBfzG3cH",
    "acct_1IyGiKQS9hvlT4Bm_cloudlive_A": "https://buy.stripe.com/6oE4jA5zu1ftevCbJq",
    "acct_1IyGiKQS9hvlT4Bm_cloudlive_B": "https://buy.stripe.com/6oE2bs2ni5vJ73adRz",
    "acct_1IyGiKQS9hvlT4Bm_cloudlive_C": "https://buy.stripe.com/00g7vM2nicYb87e3cW",
    "acct_1IyGiKQS9hvlT4Bm_booths": "https://buy.stripe.com/8wM4jA1je2jx87e3cX"
  },
  STRIPE_PRICES: {
    oneTime_fee: 'price_1LNCPOKKCurCNdVKaFpj7eak',
    full_package: 'price_1LNCPHKKCurCNdVKQUbSf9ph',
    lite_package: 'price_1LNCP8KKCurCNdVKHDSdxGHr',
    booth: 'price_1LNCPaKKCurCNdVKVQtsKiH7',
    livestraming: 'price_1LNCPfKKCurCNdVKJXBy7X9S',
    CloudLive_A_1: 'price_1LNCPwKKCurCNdVKlyPppycr',
    CloudLive_A_2: 'price_1LNCPwKKCurCNdVKn3elNyZK',
    CloudLive_B_1: 'price_1LNCPkKKCurCNdVKchvv5r6r',
    CloudLive_B_2: 'price_1LNCPkKKCurCNdVK0MwHB2dA',
    CloudLive_C_1: 'price_1LNCPnKKCurCNdVKSH0Lfz1s',
    CloudLive_C_2: 'price_1LNCPnKKCurCNdVKY3LpG2fd',
    support1: 'price_1Lni5dKKCurCNdVKOHOtHIMW',
    support2: 'price_1Lni76KKCurCNdVKPjafISZ2',
    support3: 'price_1Lni8VKKCurCNdVKy7NdErft',
  },
  loginRoute: '/login',
  registerRoute: '/register',
  NBCA_ORG_ID: '60fe9195569be70af0de6f2c',
  NBCA_MAIN_URL: 'https://nbca.accounts.stellar.online',
  NBCA_DEFAULT_TYPE: 8,
};