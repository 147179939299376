import { Component, Input, OnInit } from "@angular/core";

declare var pannellum: any;

interface PanoramaObject {
    type: string,
    autoLoad: boolean,
    showControls: boolean,
    panorama: string,
    pitch: any,
    yaw: any,
    autoRotate: any,
    hotSpots: any
}

@Component({
    selector: 'app-panorama',
    templateUrl: './panorama.component.html',
    styleUrls: ['./panorama.component.scss']
})

export class PanoramaComponent implements OnInit {

    @Input() panObject: PanoramaObject = {
        type: 'equirectangular',
        autoLoad: true,
        showControls: false,
        panorama: '/assets/images/bma-1.jpg',
        pitch: 14,
        yaw: 1.5,
        autoRotate: -2,
        hotSpots: [ // you can remove this after this is implemented
            {
                "pitch": 14.1,
                "yaw": 1.5,
                "cssClass": "custom-hotspot",
                "createTooltipFunc": this.hotspot,
                "createTooltipArgs": "Tooltip 1"
            },
            {
                "pitch": -9.4,
                "yaw": 222.6,
                "cssClass": "custom-hotspot",
                "createTooltipFunc": this.hotspot,
                "createTooltipArgs": "Tooltip 2"
            },
            {
                "pitch": -0.9,
                "yaw": 144.4,
                "cssClass": "custom-hotspot",
                "createTooltipFunc": this.hotspot,
                "createTooltipArgs": "Tooltip 3"
            }
        ]
    };

    panorama: any = undefined;

    constructor() { }

    ngOnInit() {
        this.initPanorama();
    }

    initPanorama() {
        this.panorama = pannellum.viewer('panorama', this.panObject);
    }

    // Hot spot creation function
    hotspot(hotSpotDiv, args) {
        console.log(hotSpotDiv);
        console.log(args);
        hotSpotDiv.classList.add('custom-tooltip');
        var span = document.createElement('span');
        span.innerHTML = args;
        hotSpotDiv.appendChild(span);
        span.style.width = span.scrollWidth - 20 + 'px';
        span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
        span.style.marginTop = -span.scrollHeight - 12 + 'px';
    }
}